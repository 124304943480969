/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("./src/styles/global.css")
require("typeface-parisienne")
require("typeface-satisfy")
require("typeface-source-sans-pro")
require("react-responsive-carousel/lib/styles/carousel.min.css")
require("react-typist/dist/standalone/Typist")
require("react-typist/dist/Typist.css")
