module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-135085180-8"],"pluginConfig":{"head":false}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wedding Website By Sawir Studio","short_name":"Wedding website by Sawir Studio","start_url":"/","background_color":"#CBAA5C","theme_color":"#CBAA5C","display":"minimal-ui","icon":"src/images/sawirstudiowedding-icon.png"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
